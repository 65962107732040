import React from "react";

const index = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center">
        Terms and Conditions for FEDES AI (PVT) LTD
      </h1>
      <p className="text-muted text-center">Last Updated: 3rd July 2024</p>
      <div className="mt-4">
        <ol>
          <h3>
            <li>Introduction</li>
          </h3>
          <p>
            These Terms and Conditions govern your use of the FEDES AI mobile
            application. By using our app, you agree to comply with and be bound
            by these terms.
          </p>
          <h3>
            <li>Use of the App</li>
          </h3>
          <ul>
            <li>
              The app is designed to provide face emotion detection services.
            </li>
            <li>
              You must not misuse the app or attempt to access it using
              unauthorized means.
            </li>
          </ul>
          <h3>
            <li>Privacy</li>
          </h3>
          <p>
            Your privacy is important to us. Please review our{" "}
            <a href="/privacy">Privacy Policy</a> to understand how we collect,
            use, and protect your personal information.
          </p>
          <h3>
            <li>User Obligations</li>
          </h3>
          <ul>
            <li>
              You must provide accurate and complete information when creating
              an account.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account information and password.
            </li>
          </ul>
          <h3>
            <li>Intellectual Property</li>
          </h3>
          <p>
            All content, trademarks, and data on this app are the property of
            FEDES AI (PVT) LTD or its licensors and are protected by copyright
            laws.
          </p>
          <h3>
            <li>imitation of Liability</li>
          </h3>
          <p>
            FEDES AI (PVT) LTD will not be liable for any indirect, incidental,
            or consequential damages arising from your use of the app.
          </p>
          <h3>
            <li>Changes to Terms</li>
          </h3>
          <p>
            We may update these Terms and Conditions from time to time. You will
            be notified of any changes, and your continued use of the app
            constitutes acceptance of the revised terms.
          </p>
          <h3>
            <li>Termination</li>
          </h3>
          <p>
            We reserve the right to terminate your access to the app for any
            reason, including violation of these Terms and Conditions.
          </p>
          <h3>
            <li>Governing Law</li>
          </h3>
          <p>
            These Terms and Conditions are governed by the laws of Sri Lanka.
            Any disputes will be resolved in the courts of Sri Lanka.
          </p>
          <h3>Contact Us</h3>
          <p>
            For any questions about these Terms and Conditions, please contact
            us at <a href="mailto:contact@fedesai.com">contact@fedesai.com</a>.
          </p>
        </ol>
      </div>
    </div>
  );
};

export default index;
