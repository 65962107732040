import React, { useState, useEffect } from "react";
import "./style.css";

const VideoSection = () => {
  const [youtubeUrl, setYoutubeUrl] = useState("");

  useEffect(() => {
    fetch("/youtube.json")
      .then((response) => response.json())
      .then((data) => {
        setYoutubeUrl(data.youtubeUrl);
      })
      .catch((error) => console.error("Error fetching YouTube URL:", error));
  }, []);

  return (
    <div className="video-container">
      <h2 id="howItwork">How it works?</h2>
      <div className="video-wrapper">
        <iframe
          width="1248"
          height="628"
          src={youtubeUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoSection;
