import React, { Component } from "react";
import "./style.css";

export default class index extends Component {
  render() {
    return (
      <div className="containers">
        <h2 id="UniqueTitle">Unique features</h2>
        <div class="row">
          <div class="col-sm-6">
            <div class="card animated-card" id="Unique">
              <div class="card-body d-flex flex-column">
                <div id="cardContent" class="card-content">
                  <img
                    src="/images/smartPhone.png"
                    alt="Logo"
                    id="cardLogo"
                    className="img-fluid"
                  />
                  <p class="card-text">
                    Access FEDES anytime, anywhere with our mobile app, ensuring
                    seamless integration into your daily classroom activities
                    and learning sessions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card animated-card" id="Unique">
              <div class="card-body d-flex flex-column">
                <div id="cardContent" class="card-content">
                  <img
                    src="/images/vector.png"
                    alt="Logo"
                    id="cardLogo"
                    className="img-fluid"
                  />
                  <p class="card-text">
                    Receive detailed, task-specific emotional insights that help
                    tailor teaching methods to individual student needs,
                    improving overall engagement and performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="card animated-card" id="Unique">
              <div class="card-body d-flex flex-column">
                <div id="cardContent" class="card-content">
                  <img
                    src="/images/dollar.png"
                    alt="Logo"
                    id="cardLogo"
                    className="img-fluid"
                  />
                  <p class="card-text">
                    Our cost-effective platform provides powerful emotion
                    detection technology without breaking the bank, making it
                    accessible for schools and educators.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card animated-card" id="Unique">
              <div class="card-body d-flex flex-column">
                <div id="cardContent" class="card-content">
                  <img
                    src="/images/face.png"
                    alt="Logo"
                    id="cardLogo"
                    className="img-fluid"
                  />
                  <p class="card-text">
                    Analyze students' emotions instantly using AI-powered facial
                    recognition, allowing for immediate adjustments to enhance
                    learning experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
