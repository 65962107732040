import React from "react";

const index = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Refund Policy for FEDES AI (PVT) LTD</h1>
      <p className="text-muted text-center">Last Updated: 3rd July 2024</p>
      <div className="mt-4">
        <ol>
          <h3>
            <li>Introduction</li>
          </h3>
          <p>
            At FEDES AI, we strive to provide the best service possible. This
            Refund Policy outlines the circumstances under which we will provide
            refunds for transactions made through our mobile application.
          </p>
          <h3>
            <li>Refund Eligibility:</li>
          </h3>
          <p>
            Refunds are only available for transactions that were mistakenly
            made. This includes accidental payments or duplicate transactions.
          </p>
          <h3>
            <li>Non-Refundable Transactions:</li>
          </h3>
          <p>
            Once a payment has been made for our services, it is considered
            final, and we do not offer refunds for completed services. This
            policy helps ensure the sustainability of our services and the
            quality of our offerings.
          </p>
          <h3>
            <li> Requesting a Refund:</li>
          </h3>
          <p>
            To request a refund for a mistaken transaction, please follow these
            steps:
          </p>
          <ol>
            <li>
              Contact our support team at contact@fedesai.com within 7 days of
              the transaction.
            </li>
            <li>
              Provide your transaction details, including the date, amount, and
              a brief explanation of why you believe the transaction was made in
              error.
            </li>
            <li>
              Our support team will review your request and respond within 5
              business days.
            </li>
          </ol>
          <h3>
            <li>Processing Refunds:</li>
          </h3>
          <p>
            If your refund request is approved, we will process the refund to
            the original payment method used within 14 business days. You will
            receive a confirmation email once the refund has been processed.
          </p>

          <h3>
            <li>Contact Us</li>
          </h3>
          <p>
            For any questions about these Terms and Conditions, please contact
            us at <a href="mailto:contact@fedesai.com">contact@fedesai.com</a>.
          </p>
        </ol>
      </div>
    </div>
  );
};

export default index;
