import React, { Component } from "react";
import "./style.css";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialLinks: [], // Initialize the state to hold the social links
    };
  }

  componentDidMount() {
    fetch("/links.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ socialLinks: data.social });
      })
      .catch((error) => console.error("Error fetching links:", error));
  }

  scrollToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  render() {
    return (
      <div id="footerContainer" style={{ marginBottom: "-3%" }}>
        <div>
          <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top footer-border">
            <div id="companyNameDiv">
              <div className="text-center">
                <span
                  id="companyName"
                  className="mb-3 mb-md-3 text-body-secondary"
                >
                  © Product of FONIX Software Solutions PVT LTD 🚀
                </span>
              </div>
              <div className="text-center">
                <span id="companyName" className="text-body-secondary">
                  +94770508710 | contact@fedesai.com
                </span>
              </div>
            </div>
            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex"></ul>
          </footer>
        </div>

        <div className="b-example-divider"></div>

        <div className="container">
          <footer className="py-3 my-4 footer-border">
            <ul className="nav justify-content-center pb-3 mb-3">
              <li className="nav-item" id="navItem">
                <a
                  href="#1"
                  className="nav-link px-2 text-body-secondary"
                  onClick={() => this.scrollToSection("Header")}
                >
                  Home
                </a>
              </li>
              <li className="nav-item" id="navItem">
                <a
                  href="#1"
                  className="nav-link px-2 text-body-secondary"
                  onClick={() => this.scrollToSection("Youtube")}
                >
                  How it works?
                </a>
              </li>

              <li className="nav-item" id="navItem">
                <a href="/terms" className="nav-link px-2 text-body-secondary">
                  Terms & Conditions
                </a>
              </li>
              <li className="nav-item" id="navItem">
                <a
                  href="/privacy"
                  className="nav-link px-2 text-body-secondary"
                >
                  Privacy & Policy
                </a>
              </li>
              <li className="nav-item" id="navItem">
                <a href="/refund" className="nav-link px-2 text-body-secondary">
                  Refund Policy
                </a>
              </li>
            </ul>
            <ul className="nav justify-content-center pb-3 mb-3">
              {this.state.socialLinks.length > 0 &&
                this.state.socialLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link.href} target="_blank" rel="noreferrer">
                      <img src={link.src} alt={link.alt} />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </li>
                ))}
            </ul>
          </footer>
        </div>
      </div>
    );
  }
}
