import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./pages/landingPage";
import Privacy from "./pages/privacyPage";
import Terms from "./pages/termsPage";
import Refund from "./pages/refundPage";

export default class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={LandingPage} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/refund" exact component={Refund} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
