import React, { useEffect, useRef, useState } from "react";
import "./style.css";

const TeacherTestimonials = () => {
  const scrollRef = useRef(null);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const scroll = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft += 1;
        if (scrollRef.current.scrollLeft >= scrollRef.current.scrollWidth / 2) {
          scrollRef.current.scrollLeft = 0;
        }
      }
    };

    const interval = setInterval(scroll, 20);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetch("/testimonials.json")
      .then((response) => response.json())
      .then((data) => setTestimonials(data))
      .catch((error) => console.error("Error fetching testimonials:", error));
  }, []);

  return (
    <div
      className="container"
      id="TeacherContainer"
      style={{ overflowX: "hidden" }}
    >
      <h1 id="WhyTeacherTitle">Why teachers like to use FEDES?</h1>
      <div className="row" ref={scrollRef}>
        <div className="scrolling-wrapper">
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <div className="col" key={index} style={{ padding: "2%" }}>
              <div className="card" id="teacherCard">
                <div className="card-body">
                  <h5 className="card-title">
                    <img src={testimonial.imgSrc} alt="" id="star" />
                  </h5>
                  <p className="card-text" id="teacherText">
                    {testimonial.text}
                  </p>
                  <div className="row">
                    <div className="col-md-3">
                      <img src={testimonial.logoSrc} alt="" id="teacherLogo" />
                    </div>
                    <div className="col-md-6">
                      <span id="teacherName">{testimonial.name}</span>
                      <br />
                      <span id="subject">{testimonial.subject}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeacherTestimonials;
