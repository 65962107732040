import React, { Component } from "react";
import "./style.css";

export default class index extends Component {
  render() {
    return (
      <div id="whatIsContainer">
        <h2 id="whatTitle">What is FEDES?</h2>
        <div className="row" id="imgRow">
          <div className="col">
            <div id="FedesImgDiv">
              <img
                src="/images/Fedes.png"
                alt="logo"
                id="FedesImg"
                className="img-fluid"
              ></img>
            </div>
          </div>
          <div className="col" id="whatCol2">
            <div id="whatIsText">
              <span>
                <b>FEDES (Face Emotion Detection Examination System)</b> is an
                innovative mobile app that uses AI-powered facial recognition to
                analyze students' emotional states in real-time. This technology
                provides educators with valuable insights, allowing them to
                adapt teaching strategies and create more engaging,
                personalized, and effective learning environments.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
