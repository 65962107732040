import React, { Component } from "react";
import Unique from "../componenet/uniqueFeatures/index";
import WhatIsFEDES from "../componenet/whatIsFedes/index";
import WhatOther from "../componenet/whatOtherPeopleAsk/index";
import ContactUs from "../componenet/contactUs/index";
import WhyTeacher from "../componenet/whyTeachersLikeToUseFedes/index";
import Footer from "../componenet/footer/index";
import Header from "../componenet/header/index";
import Youtube from "../componenet/youtube/index";
import "./style.css";

export default class landingPage extends Component {
  render() {
    return (
      <div id="main">
        <header className="Header" id="Header">
          <Header />
        </header>

        <section className="Youtube" id="Youtube">
          <Youtube />
        </section>

        <section className="WhatIsFEDES" id="WhatIsFEDES">
          <WhatIsFEDES />
        </section>

        <section className="unique-center" id="unique-center">
          <Unique />
        </section>

        <section className="WhatOther" id="WhatOther">
          <WhatOther />
        </section>

        <section className="ContactUs" id="ContactUs">
          <ContactUs />
        </section>

        <section id="WhyTeacher">
          <WhyTeacher />
        </section>

        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}
