import React, { Component } from "react";
import "./style.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/800.css";
import config from "../../config";

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavbarOpen: false,
      showScrollButton: false,
      links: [],
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    fetch("/links.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ links: data.downloads });
      })
      .catch((error) => console.error("Error fetching links:", error));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  toggleNavbar() {
    this.setState({ isNavbarOpen: !this.state.isNavbarOpen });
  }

  scrollToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleScroll() {
    if (window.scrollY > 300) {
      this.setState({ showScrollButton: true });
    } else {
      this.setState({ showScrollButton: false });
    }
  }

  render() {
    return (
      <div id="headerContainer">
        <header>
          {/* Navbar */}
          <nav className="navbar navbar-expand-lg navbar-light">
            <div id="fedesLogoDiv">
              <img
                src="/images/fedesLogo.png"
                alt="logo"
                id="fedesLogo"
                className="img-fluid"
              />
            </div>
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                onClick={this.toggleNavbar}
              >
                <i className="fas fa-bars"></i>
              </button>
              <div
                className={`collapse navbar-collapse ${
                  this.state.isNavbarOpen ? "show" : ""
                }`}
              >
                <ul
                  className="navbar-nav me-auto ms-auto mb-3 mb-lg-0"
                  id="list"
                >
                  <li className="nav-item active">
                    <a
                      className="nav-link"
                      aria-current="page"
                      href="#1"
                      id="navName"
                      onClick={() => this.scrollToSection("Header")}
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#1"
                      id="navName"
                      onClick={() => this.scrollToSection("Youtube")}
                    >
                      How it works?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#1"
                      id="navName"
                      onClick={() => this.scrollToSection("WhatIsFEDES")}
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#1"
                      id="navName"
                      onClick={() => this.scrollToSection("ContactUs")}
                    >
                      Contact
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={config.LOGIN}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="navName"
                    >
                      Log In
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={config.REGISTER}
                       target="_blank"
                      rel="noopener noreferrer"
                      id="navNameTeacher"
                    >
                      <span id="signupTeacher" className="centered-text-border">
                        Sign Up as a Teacher
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* Navbar */}

          {/* Background image */}
          <div>
            <div className="mask">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="text-white">
                  <div id="new">
                    <div>
                      <span id="headerTitle">Empowering Educators</span>
                    </div>
                    <h5 className="mb-4">
                      <span id="headerTitle">with</span>&nbsp;&nbsp;
                      <span id="Emotion">Emotion Detection.</span>
                    </h5>
                  </div>
                  <div id="subHeadingDiv" className="text-center">
                    <div>
                      <span id="subHeading1">
                        Enhance teaching with real-time emotion detection,
                        personalized strategies, and{" "}
                      </span>
                    </div>
                    <div>
                      <span id="subHeading2">
                        improved student engagement using our innovative
                        AI-powered educational app.{" "}
                      </span>
                    </div>
                  </div>
                  <div className="text-center">
                    <span id="clickHere">Click Here to Download</span>
                  </div>
                  <div className="row" id="buttonSection">
                    {this.state.links.map((link, index) => (
                      <div className="col-md-2" key={index}>
                        <a href={link.href} target="_blank" rel="noreferrer">
                          <img src={link.src} alt={link.alt} />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div id="reportImgDiv">
                <img
                  src="/images/Report.png"
                  alt="logo"
                  id="reportImg"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          {/* Background image */}
        </header>
        {/* Scroll to Top Button */}
        {this.state.showScrollButton && (
          <button className="scroll-to-top" onClick={this.scrollToTop}>
            ↑
          </button>
        )}
      </div>
    );
  }
}
