import React from 'react'
import SubHeader from "../componenet/subHeader/index";
import Footer from "../componenet/footer/index";
import Refund from "../componenet/refund/index"

export default function refundPage() {
  return (
    <div id="main">
    <header className="Header" id="Header">
      <SubHeader />
    </header>
    <section>
       <Refund/>
    </section>
    <footer>
      <Footer />
    </footer>
  </div>
  )
}
