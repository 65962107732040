import React from "react";

const index = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Privacy Policy for FEDES AI (PVT) LTD</h1>
      <p className="text-muted text-center">Last Updated: 3rd July 2024</p>
      <div className="mt-4">
        <ol>
          <h3>
            <li>Introduction</li>
          </h3>
          <p>
            Welcome to FEDES AI! We value your trust and are committed to
            safeguarding your personal information. This Privacy Policy explains
            how we collect, use, and protect your data when you use our mobile
            application.
          </p>
          <h3>
            <li>Information We Collect</li>
          </h3>
          <ol>
            <h5>
              <li>Personal Information</li>
            </h5>
            <ul>
              <li>Name, Email, Contact details</li>
            </ul>
            <h5>
              <li>Usage Data</li>
            </h5>

            <ul>
              <li>App Features Accessed, Time Spent</li>
            </ul>
          </ol>
          <h3>
            <li>How We Collect Data</li>
          </h3>
          <ul>
            <li>
              <strong>Direct User Input:</strong> Information provided during
              account creation or in-app interactions.
            </li>
            <li>
              <strong>Automated Means:</strong> Through cookies and similar
              technologies for analytics and app enhancement.
            </li>
            <li>
              <strong>Third-Party Integrations:</strong> Firebase, One Signal
            </li>
          </ul>
          <h3>
            <li>Purpose of Data Collection</li>
          </h3>
          <ul>
            <li>Providing and maintaining app functionality.</li>
            <li>Personalising your experience.</li>
            <li>Analyzing app usage for improvements.</li>
            <li>Sending important notifications and updates.</li>
          </ul>

          <h3>
            <li>Data Usage and Sharing</li>
          </h3>
          <ul>
            <p>
              <li>We do not sell your personal information.</li>
            </p>
            <p>
              <li>Data may be shared with third parties for:</li>
            </p>
            <ul>
              <li>
                <strong>Analytics:</strong> To understand user behavior and
                enhance app performance.
              </li>
              <li>
                <strong>Service Providers:</strong> To assist with app-related
                services.
              </li>
            </ul>
          </ul>
          <h3>
            <li>Security Measures</li>
          </h3>
          <p>
            We employ industry-standard measures, including encryption and
            secure transmission protocols, to protect your data from
            unauthorized access and misuse.
          </p>
          <h3>
            <li>User Rights</li>
          </h3>
          <p>
            You have the right to access your data, rectify any inaccuracies, or
            request the deletion of your information. You can manage your
            privacy settings within the app.
          </p>
          <h3>
            <li>Policy Changes</li>
          </h3>
          <p>
            This Privacy Policy may be updated, and you will be notified of any
            significant changes. Your continued use of the app after such
            modifications implies your consent.
          </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions or concerns regarding this Privacy Policy,
            please contact us at{" "}
            <a href="mailto:contact@fedesai.com">contact@fedesai.com</a>.
          </p>
        </ol>
      </div>
    </div>
  );
};

export default index;
