import React from 'react'
import SubHeader from "../componenet/subHeader/index";
import Footer from "../componenet/footer/index";
import Terms from "../componenet/terms/index"

export default function termsPage() {
  return (
    <div id="main">
        <header className="Header" id="Header">
          <SubHeader />
        </header>
        <section>
           <Terms/>
        </section>
        <footer>
          <Footer />
        </footer>
      </div>
  )
}
